












































































































































































.switch {
  transform: scale(1.2);
  transform-origin: left;
  height: 1.7rem;
  width: 2.6rem;

  ::v-deep label {
    font-size: 0.857rem;
  }
}
